.home-side-bonus{
  :global{
    .adm-badge-dot{
      right: .75rem;
      top: .75rem;
    }
  }
}

.home-banner-bullet-active{
  width: 0.75rem!important;
  background-color: var(--1)!important;
  transition: width 0.3s ease-in-out;
}

.home-banner-bullet{
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: #E8EBEE;
  opacity: 1;
}

.home-banner-bullet-box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  --swiper-pagination-bottom: 0.75rem;
}