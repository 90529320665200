.hot-title-wrap {
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  display: flex;
  width: 23.4375rem;
  height: 2.5rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background: linear-gradient(
    180deg,
    rgba(201, 228, 209, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.hot-title-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.title-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  position: relative;
  z-index: 2;
}
.title-icon {
  width: 0.8rem;
}
.left-text {
  color: #000;
  font-family: Inter-Bold;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.title-right {
  color: #31373d;
  text-align: right;
  font-family: Inter-Bold;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  text-transform: capitalize;
}
.hot-content {
  box-sizing: border-box;
  padding: 0 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.dice-color {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.dice-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #0080ff 0%, #005fbe 100%);
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); */
  position: relative;
}
.dice-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
}
.dice-card-content-title {
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.21);
  font-family: Inter-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.dice-card-content-max {
  display: block;
  color: #fff;
  font-family: Inter-Bold;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dice-card-content-mul {
  display: block;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  font-family: Inter-Bold;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  background: linear-gradient(
    98deg,
    #fff8da 15.92%,
    #eed671 46.23%,
    #fff4e1 68.13%,
    #d8b26a 92.64%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color-card {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #74c136 0%, #1d9629 100%);
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); */
}
.quick-3d,
.race-car {
  position: relative;
  width: 21.9375rem;
  height: 6.9375rem;
}
.avatars-wrap {
  position: absolute;
  bottom: 0.8rem;
  left: 11.1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 9999px;
  overflow: hidden;
}
.avatars-container {
  position: relative;
  width: 4.25rem;
  overflow: hidden;
}
.avatars-wrapper {
  display: flex;
  align-items: center;
}
.avatar-item {
  transition: all 0.3s ease-out;
  flex-shrink: 0;
  margin-right: -0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.avatar-hidden {
  position: relative;
  left: 2rem;
}
.avatar-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.avatar-item:first-child.animating {
  transform: scale(0);
  opacity: 0;
}
.avatar-item.avatar-show {
  animation: bubbleUp 0.3s ease-out;
  animation-delay: 0.3s;
}
@keyframes bubbleUp {
  0% {
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
  60% {
    transform: translateX(-20%) scale(1.1);
  }
  80% {
    transform: translateX(5%) scale(0.95);
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}
.big-right-content {
  display: flex;
  width: 10.09375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}
.big-right-content-top {
  border-radius: 0.25rem;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 0.5rem 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.content-top-bet {
  align-self: stretch;
  color: #fff;
  font-family: Inter-Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.content-top-won {
  align-self: stretch;
  color: #fff;
  font-family: Inter-Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: lowercase;
}
.content-top-won-number {
  font-size: 1.25rem;
  font-weight: 900;
}
.big-right-content-bottom {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.bottom-avatars {
  display: flex;
  align-items: flex-start;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.bottom-numbers {
  color: #fff;
  text-align: center;
  font-family: Inter-Bold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.7;
}
.coner-rate {
  position: absolute;
  z-index: 10;
  top: -0.125rem;
  left: 0;
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem 0.5rem 0 0;
  background: linear-gradient(90deg, #e15600 0%, #bd0000 100%);
}
.coner-rate-text {
  color: #fff;
  text-align: center;
  font-family: Inter-Bold;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.coner-rate-rate {
  font-size: 0.875rem;
  font-weight: 900;
}
.other-games-wrap {
}
.avatar-wrapper {
  transition: all 0.3s ease-out;
}
.avatar-wrapper:first-child.animating {
  transform: scale(0);
  opacity: 0;
}
.avatar-wrapper:last-child.animating {
  animation: bubbleUp 0.3s ease-out;
  animation-delay: 0.3s;
}
